import React from "react"
import { Link } from "gatsby"
import PageWrapper from "../../components/PageWrapper"

const TreatmentsPage = () => (
    <>
  <PageWrapper headerDark footerDark>
    <h1>Treatments</h1>
    <p>Welcome to the treatments page.</p>
    <ul>
      <li><Link to="/treatments/microneedling">Microneedling</Link></li>
      {/* Add more links to other treatments here */}
    </ul>
  </PageWrapper>
  </>
)

export default TreatmentsPage
